export const concerts = [
    {
        id: '74',
        date: '21.06.2024',
        place: 'Wrocław Opera',
        title: "Fête de la Musique",
        subtitle: '',
        cast: ['Maria Rozynek - sopran', 'Jacek Jaskuła - baryton', 'Aleksander Zuchowicz - tenor', 'Aleksandra Opała - mezzosopran', 'Jadwiga Postrożna - mezzosopran', 'Tomasz Rudnicki - bas', 'Katarzyna Haras - mezzosopran', 'Łukasz Motkowicz - baryton', 'Agnieszka Adamczak - sopran', 'Eliza Kruszczyńska - sopran', 'Elżbieta Kaczmarzyk-Janczak - mezzosopran', 'Barbara Bagińska - mezzosopran', 'Łukasz Rosiak - baryton', 'Łukasz Gaj - tenor'],
        orchestra: 'Choir and Orchestra of Wrocław Opera',
        choreography: '',
        conductor: 'Rafał Karczmarczyk',
        scenography: '',
        balletDirector: '',
        premiere: '',
        assistant: ''
    },
    {
        id: '73',
        date: '08.06.2024 | 09.06.2024 | 11.06.2024',
        place: 'Wrocław Opera',
        title: "Napoli 1841",
        subtitle: 'Holger Paulli , Eduard Helsted , Niels Wilhelm Gade , Hans Christian Lumbye',
        cast: [`Marcin Kupiński - Bournonville`, 'Mizuno Toma - Caroline', 'Alessandro Ciotta - Pierwszy tancerz', 'Armando Barros - Rybak', 'Daniel Agudo Gallardo - Dyrektor', 'Pablo Martinez Mendez - Artysta śpiewak', 'Sylwia Miazga - Sprzedawca lemoniady', 'Sergi Martinez  Castello - Sprzedawca makaronu', 'Piotr Oleksiak - Ksiądz', 'Lucas Hessel Rafalovich - Muzyk', 'Natalia Augustyniak, Miho Okamura, Maki Sekuzu, Risa Sasaki, Leonie Hansen, Sherly Belliard, Magdalena Kurilec-Malinowska, Nonka Miyamoto - Tancerki', 'Kei Otskua Keisuke Sakai David Oscar Blat Andrzej Malinowski Massimo Colonna Romano Dino Staković - Tancerze', 'Maki Sekuzu Risa Sasaki Leonie Hansen Sherly Belliard David Oscar Blat Keisuke Sakai Miho Okamura Natalia Augustyniak Kei Otsuka - Pas de six i wariacje'],
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Johan Kobborg | August Bournonville',
        conductor: 'Rafał Karczmarczyk',
        scenography: 'Otto Bubeníček',
        balletDirector: 'Małgorzata Dzierżon',
        premiere: 'PREMIERE',
        assistant: ''
    },
    {
        id: '72',
        date: '18.05.2024',
        place: 'Wrocław Opera',
        title: "L'Italiana in Algeri",
        subtitle: 'Gioacchino Rossini',
        cast: ['Szymon Kobyliński, Jakub Szmidt - Mustafa', 'Iwona Handzlik, Tatiana Hempel-Gierlach - Elwira', 'Paweł Żak, Aleksander Kunach - Lindoro', 'Aleksandra Opała, Jolana Slavikova - Izabela', 'Elżbieta Kaczmarzyk-Janczak, Dorota Dutkowska - Zulma', 'Adam Woźniak - Haly', 'Tomasz Łykowski - Taddeo'],
        orchestra: 'Choir and Orchestra of Wrocław Opera',
        choreography: 'Janina Niesobska',
        director: 'Waldemar Zawodziński',
        music: '',
        conductor: 'Georgios Balatsinos',
        musicalCooperation: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: ''
    },
    {
        id: '71',
        date: '12.04.2024 | 20.04.2024',
        place: 'Wrocław Opera',
        title: 'Die Zauberflöte (reduction)',
        subtitle: 'Wolfgang Amadeus Mozart',
        cast: ['Jakub Michalski - Sarastro', 'Teresa Marut - Królowa Nocy', 'Dorota Laskowiecka-Urban - Pamina'],
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Bartosz Buława',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '70',
        date: '16.03.2024 | 17.03.2024',
        place: 'Wrocław Opera',
        title: 'Don Juan',
        subtitle: 'Christoph Wilibald Gluck',
        cast: ['Daniel Agudo Gallardo - Don Juan','Kei Otsuka - Zanni', 'Nonoka Miyamoto - Donna Elwira', 'Mizuno Toma - Donna Anna', 'Magdalena Kurilec-Malinowska - Donna Izabella', 'Piotr Oleksiak - Komandor', 'Andrzej Malinowski - Diabeł', 'Natalia Augustyniak - Eliza', 'Sava Cebanu - Carino', 'Lucas Hessel Rafalovich - Don Ottavio', 'Anna Czermak - violin', 'Paula Dzwonik, Paulina Grodzińska, Shantana Jurkovic Hull, Maki Sekuzu, Zofia Korpacka, Leonie Hansen, Miho Okamura, Agata Załuska - Panie', 'David Oscar Blat, Alessandro Ciotta, Kei Otsuka, Dino Staković, Davide Piccoli, Sergi Martinez Castello, Keisuke Sakai, Armando Barros, Pablo Martinez Mendez, Lucas Hessel Rafalovich - Panowie'],
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Giorgio Madia',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '69',
        date: '09.03.2024 | 10.03.2024 | 12.03.2024',
        place: 'Wrocław Opera',
        title: 'Die Zauberflöte (reduction)',
        subtitle: 'Wolfgang Amadeus Mozart',
        cast: ['Aleksander Zuchowicz - Tamino', 'Dorota  Laskowiecka-Urban - Pamina', 'Jacek Jaskuła - Papageno', 'Liliana Jędrzejczak - I Dama', 'Barbara Bagińska - III Dama', 'Katarzyna Haras - Papagena'],
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Bartosz Buława',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '68',
        date: '23.02.2024 | 24.02.2024 | 25.02.2024',
        place: 'Wrocław Opera',
        title: 'Giselle',
        subtitle: 'Adam Adolphe',
        cast: ['Sherly Belliard, Mizuno Toma - Giselle', 'Łukasz Ożga, Andrzej Malinowski - Albert', 'Daniel Agudo Gallardo, Lucas Hessel Rafalovich - Hilarion', 'Magdalena Kurilec-Malinowska, Agata Załuska - Batylda', 'Sergi Martinez Castello, Sava Cebanu - Wilfryd', 'Lucas Hessel Rafalovich, Armando Barros - Książę', 'Anna Szopa-Kimso - Berta', 'Magdalena Kurilec-Malinowska, Risa Sasaki - Mirta', 'Miho Okamura, Kei Otsuka, Maki Sekuzu, Masimo Colonna Romano - Pas de deux', 'Paula Dzwonik, Mizuno Toma, Maki Sekuzu, Risa Sasaki - Przyjaciółki Giselle', 'Karolina Jankowska, Shantana Hull Jurkovic, Nonoka Miyamoto, Natalia Augustyniak, Miho Okamura, Paulina Grodzińska, Agata Załuska, Zofia Korpacka - Walc Panie', 'Shantana Hull Jurkovic, Nonoka Miyamoto, Natalia Augustyniak, Miho Okamura, Paulina Grodzińska, Zofia Korpacka - Taniec Wiejski Panie', 'David Oscar Blat, Alessandro Ciotta, Sava Cebanu, Davide Piccoli, Massimo Colonna Romano, Dino Staković - Taniec Wiejski Panowie', 'Piotr Oleksiak, Keisuke Sakai, Armando Barros, Julian Żychowicz, Jagoda Kowal, Kamila Brall - Dwór', 'Maki Sekuzu Paula Dzwonik - Willidy', 'Karolina Jankowska Leonie Hansen Risa Sasaki Agata Załuska Natalia Augustyniak Shantana Hull&#160;Jurkovic Zofia Korpacka Nonoka Miyamoto Jagoda Kowal* Kamila Brall - Willidy Panie (akt II)'],
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Jean Coralli, Jules Perrot',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '67',
        date: '25.01.2024 - 11.02.2024',
        place: 'Wrocław Opera',
        title: 'Fiddler on the Roof',
        subtitle: 'Joseph Stein, Jerry Bock, Sheldon Harnick',
        cast: ['Grzegorz Szostak, Jacek Jaskuła - Tewje', 'Barbara Bagińska, Elżbieta Kaczmarzyk-Janczak - Gołda', 'Dorota Dutkowska, Liliana Jędrzejczak - Cajtla', 'Aleksandra Opała, Maria Rozynek - Hudel', 'Joanna Nawrot, Aleksandra Malisz - Chawa', 'Łukasz Rosiak, Tomasz Łykowski - Perczyk', 'Elżbieta Kaczmarzyk-Janczak, Barbara Bagińska - Jenta', 'Edward Kulczyk - Abram', 'Aleksander Zuchowicz, Valerii Zadorozhnyi - Sasza', 'Tomasz Rudnicki - Policjant', 'Piotr Bunzler, Rafał Supiński - Motel', 'Jerzy Szlachcic - Lejzor', 'Marek Klimczak - Rabin', 'Paweł Walankiewicz - Mendel', 'Eliza Kruszczyńska, Jadwiga Postrożna - Fruma-Sara', 'Marcin Klarman - Dziadek', 'Andrzej Zborowski - Mordcha', 'Marcin Klarman - Nachum', 'Aliaksandr Bardasau - Fiedka', 'Jolanta Serednicka - Babcia Cajtla', 'Adam Czermak - Skrzypek'],
        orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: 'Emil Wesołowski',
        director: 'Marek Weiss',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '66',
        date: '20.01.2024 | 21.01.2024',
        place: 'Wrocław Opera',
        title: 'Don Juan',
        subtitle: 'Christoph Wilibald Gluck',
        cast: ['Łukasz Ożga - Don Juan','Kei Otsuka, Keisuke Sakai - Zanni', 'Leonie Hansen - Donna Elwira', 'Maki Sekuzu - Donna Anna', 'Sherly Belliard - Donna Izabella', 'Piotr Oleksiak - Komandor', 'Andrzej Malinowski - Diabeł', 'Natalia Augustyniak - Eliza', 'Massimo Colonna Romano - Carino', 'David Oscar Blat - Don Ottavio', 'Anna Czermak - violin', 'Paula Dzwonik, Paulina Grodzińska, Shantana Jurkovic Hull, Maki Sekuzu, Zofia Korpacka, Leonie Hansen, Miho Okamura, Agata Załuska - Panie', 'David Oscar Blat, Alessandro Ciotta, Kei Otsuka, Dino Staković, Davide Piccoli, Sergi Martinez Castello, Keisuke Sakai, Armando Barros, Pablo Martinez Mendez, Lucas Hessel Rafalovich - Panowie'],
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Giorgio Madia',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '65',
        date: '13.01.2024 | 14.01.2024',
        place: 'Wrocław Opera',
        title: 'Die Zauberflöte (reduction)',
        subtitle: 'Wolfgang Amadeus Mozart',
        cast: ['Jakub Michalski - Sarastro', 'Aleksander Zuchowicz, Jędrzej Tomczyk - Tamino', 'Marcelina Román - Pamina'],
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Bartosz Buława',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '64',
        date: '04.01.2024 | 05.01.2024 | 06.01.2024',
        place: 'Wrocław Opera',
        title: 'Ballet',
        subtitle: '',
        piece1: 'Somei Satoh, Kate Whitley - Flight',
        piece2: 'Maurice Ravel - Bolero',
        piece3: 'Cole Porter - Elsa Canasta',
        cast: ['Tomasz Rudnicki - vocal', 'Ashot Babrouski - piano', 'Adam Czermak - violin'],
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Małgorzata Dzierżon, Meryl Tankard, Javier De Frutos',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '63',
        date: '06.12.2023 | 07.12.2023',
        place: 'Wrocław Opera',
        title: 'Żywioły 2',
        subtitle: '',
        cast: '',
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Leonie Hansen, Anna Gancarz, Daniel Agudo Gallardo, Sergi Martinez Castello, Sava Cebanu, Lucas Hessel Rafalovich, David Oscar Blat, Bartłomiej Malarz, Łukasz Ożga, Anna Szopa-Kimso, Massimo Colonna Romano',
        director: '',
        music: 'Agata Zemla*, Małgorzata Szandrach*, Alina Dzięcioł*, Zuzanna Klepacka*, Tymoteusz Lasik*, Karolina Kułaga*',
        conductor: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: ''
    },
    {
        id: '62',
        date: '01.12.2023 | 02.12.2023 | 03.12.2023',
        place: 'Wrocław Opera',
        title: 'Mikołaj w mikołajki',
        subtitle: '',
        cast: '',
        orchestra: 'Children Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: 'Youri Vamos',
        director: 'Rafał Supiński',
        music: 'Rafał Kłoczko',
        conductor: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: ''
    },
    {
        id: '61',
        date: '29.11.2023 | 30.11.2023',
        place: 'Wrocław Opera',
        title: 'Die Zauberflöte (reduction)',
        subtitle: 'Wolfgang Amadeus Mozart',
        orchestra: 'Soloists, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Bartosz Buława',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '60',
        date: '15.11.2023 | 16.11.2023',
        place: 'Wrocław Opera',
        title: 'Die Zauberflöte (reduction)',
        subtitle: 'Wolfgang Amadeus Mozart',
        orchestra: 'Soloists, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Bartosz Buława',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '59',
        date: '10.10.2023 | 17.10.2023',
        place: 'Wrocław Opera',
        title: 'Die Zauberflöte (reduction)',
        subtitle: 'Wolfgang Amadeus Mozart',
        orchestra: 'Soloists, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Bartosz Buława',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '58',
        date: '01.10.2023',
        place: 'Wrocław Opera',
        title: 'The Magic Flute in Breslau',
        subtitle: 'Wolfgang Amadeus Mozart',
        cast: ['Grzegorz Szostak, Tomasz Rudnicki - Sarastro', 'Aleksander Zuchowicz, Jędrzej Tomczyk - Tamino', 'Maria Rozynek-Banaszak - Królowa Nocy', 'Marcelina Román, Dorota Laskowiecka-Urban - Pamina', 'Liliana Jędrzejczak, Eliza Kruszczyńska - I Dama', 'Dorota Dutkowska - II Dama', 'Elżbieta Kaczmarzyk-Janczak, Barbara Bagińska - III Dama', 'Paweł Szlachta - I Chłopiec', 'Artur Plinta - II Chłopiec', 'Bartosz Wasiluk - III Chłopiec', 'Łukasz Rosiak - Papageno', 'Jacek Jaskuła - Eberhard Mock', 'Katarzyna Haras, Papagena - Papagena', 'Edward Kulczyk, Krzysztof Kozarek - Monostatos', 'Christian Bild, Piotr Bunzler - Kapłan 1/ Zbrojny 1', 'Andrzej Zborowski, Marek Klimczak - Kapłan 2/ Zbrojny 2 '],
        orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Michał Znaniecki',
        conductor: 'Adam Banaszak',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    {
        id: '57',
        date: '21.09.2023 | 22.09.2023 | 23.09.2023 | 24.09.2023',
        place: 'Wrocław Opera',
        title: 'Sid – the Serpent who Wanted to Sing',
        subtitle: 'Malcolm Fox',
        cast: '',
        orchestra: 'Soloists, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Adam Frontczak',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '56',
        date: '16.09.2023 | 17.09.2023',
        place: 'Wrocław Opera',
        title: 'Le Carnaval des animaux',
        subtitle: 'Camille Saint-Saëns',
        cast: ['Mizuno Toma, Sherly Belliard - Laura', 'Dino Staković, Daniel Agudo Gallardo - Chłopiec', 'Natalia Augustyniak, Paula Dzwonik, Maki Sekuzu, Zofia Korpacka, Paulina Grodzińska - Kurczaki', 'Pablo Martinez Mendez, Łukasz Ożga - Kwoka', 'Andrzej Malinowski, Massimo Collona Romano, Sergio Martinez Mendez, David Oscar Blat - Pianiści', 'Miho Okamura, Maki Sekuzu, Andrzej Malinowski, Massimo Collona Romano - Koniki morskie'],
        orchestra: 'Anita Tashkinova, Ivan Zhukov - piano, Orchestra of Wrocław Opera',
        choreography: 'Anna Hop',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '55',
        date: '09.09.2023 | 10.09.2023',
        place: 'Wrocław Opera',
        title: 'The Cheating Flea',
        subtitle: 'Maciej Małecki',
        cast: '',
        orchestra: 'Soloists and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Anna Seniuk',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '54',
        date: '26.05.2023',
        place: 'Zielona Góra Philharmony',
        title: 'Concert',
        subtitle: '',
        piece1: '',
        cast: ['Agnieszka Przekupień', 'Tomasz Bacajewski'],
        orchestra: 'Orchestra of Zielona Góra Philharmony',
        cast1: '',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '53',
        date: '23.04.2023 | 24.04.2023',
        place: 'Gorzów Philharmony',
        title: 'Ballet Performance',
        subtitle: 'Stanisław Moniuszko',
        piece1: 'Uwertura do opery "Jawnuta"',
        piece2: 'Uwertura koncertowa "Bajka"',
        piece3: 'Uwertura do opery "Verbum nobile"',
        piece4: 'Taniec cygański z opery "Jawnuta"',
        piece5: 'Uwertura do opery "Paria"',
        piece6: 'Taniec satyrów z opery "Hrabina"',
        orchestra: 'Cracovia Danza, Orchestra of Gorzów Philharmony',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '52',
        date: '04.11.2022',
        place: 'Łódź Philharmony',
        title: 'Concert',
        subtitle: '',
        piece1: 'Witold Lutosławski – Pięć melodii ludowych',
        piece2: 'Witold Lutosławski – Dziesięć tańców polskich',
        piece3: 'Grażyna Bacewicz – Oberek',
        piece4: 'Romuald Twardowski – Oberek',
        piece5: 'Wojciech Kilar – Orawa',
        cast: '',
        orchestra: 'Orchestra of Łódź Philharmony',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '51',
        date: '25.06.2022',
        place: 'Wrocław Opera',
        title: 'Manon',
        subtitle: 'Jules Massenet',
        cast: ['Simona Mihai, Hanna Sosnowska-Bill - Manon', 'Charles Castronovo, Eric Fennell - Des Grieux', 'Szymon Mechliński, Mariusz Godlewski - Lescaut', 'Grzegorz Szostak, Jakub Michalski - Hrabia des Grieux', 'Aleksander Zuchowicz, Jędrzej Tomczyk - Guillot de Morfontaine', 'Sergii Borzov - De Brétigny', 'Dorota Dutkowska, Katarzyna Toboła - Rosette', 'Canan Kalkir, Aleksandra Malisz - Poussette', 'Aleksandra Opała, Zoia Rolinska - Javotte', 'Marek Klimczak - Oberżysta'],
        orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Waldemar Zawodziński',
        conductor: 'Bassem Akiki',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    {
        id: '50',
        date: '20.05.2022',
        place: 'Wrocław Opera',
        title: 'The Marriage of Figaro',
        subtitle: 'Wolfgang Amadeus Mozart',
        cast: ['Lukáš Bařák, Adam Kutny', 'Gabriella Pace, Kamila Dutkowska', 'Krzysztof Bączyk, Paweł Horodyski', 'Hanna Sosnowska-Bill, Aleksandra Łaska', 'Aleksandra Opała, Zuzanna Nalewajek', 'Barbara Bagińska, Dorota Dutkowska', 'Dariusz Machej, Arkadiusz Jakus', 'Jacek Jaskuła, Wojciech Urbanowski', 'Aleksander Zuchowicz, Piotr Bunzler', 'Edward Kulczyk, Jędrzej Tomczyk', 'Aleksandra Malisz, Teresa Swianiewicz'],
        orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'André Heller-Lopes',
        conductor: 'Bassem Akiki',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    {
        id: '49',
        date: '27.05.2022',
        place: 'Koszalin Philharmony',
        title: 'Concert',
        subtitle: '',
        piece1: 'Max Bruch - Kol Nidrei op. 47',
        piece2: 'Carl Maria von Weber - I Koncert f-moll op. 73 - cz. I',
        piece3: 'Grażyna Bacewicz - Duety na tematy ludowe na dwoje skrzypiec',
        piece4: 'Georg Friedrich Haendel - aria „How beautiful are the feet” z oratorium „Mesjasz”',
        piece5: 'Wolfgang Amadeus Mozart - aria Zuzanny „Un moto di gioia” z II aktu opery „Wesele Figara”',
        piece6: 'Pablo de Sarasate – Fantazja na temat z opery „Faust” op. 13',
        piece7: 'Fryderyk Chopin - Koncert e-moll op. 11 - cz. I',
        orchestra: 'Music school soloists, Koszalin Philharmonic Orchestra',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '48',
        date: '01.05.2022',
        place: 'Wrocław Opera',
        title: 'Giselle',
        subtitle: 'Adam Adolphe',
        cast: '',
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Jean Coralli, Jules Perrot',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '47',
        date: '10.03.2022 | 11.03.2022 | 13.03.2022',
        place: 'Wrocław Opera',
        title: 'Sid – the Serpent who Wanted to Sing',
        subtitle: 'Malcolm Fox',
        cast: '',
        orchestra: 'Soloists, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Adam Frontczak',
        conductor: 'Rafał Karczmarczyk',
    },
    // {
        // id: '46',
    //     date: '31.10.2021',
    //     place: 'Wrocław Opera',
    //     title: 'Halka',
    //     subtitle: 'Stanisław Moniuszko',
    //     cast: 'Joanna Zawartko, Paweł Skałuba, Dariusz Machej, Katarzyna Haras, Łukasz Rosiak, Jakub Michalski, Andrzej Zborowski, Mykhallo Hnatluk, Aleksander Zuchowicz, Piotr Bunzler',
    //     orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'Grażyna Szapołowska',
    //     conductor: 'Jakub Chrenowicz',
    //     conductorAssistant: 'Rafał Karczmarczyk',
    //     assistant: 'yes'
    // },
    {
        id: '45',
        date: '30.10.2021 | 30.05.2021',
        place: 'Wrocław Opera',
        title: 'The Cheating Flea',
        subtitle: 'Maciej Małecki',
        cast: '',
        orchestra: 'Soloists and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Anna Seniuk',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '44',
        date: '15.10.2021',
        place: 'Wrocław Opera',
        title: 'Der Freischütz',
        subtitle: 'Carl Maria von Weber',
        cast: ['Aleksandra Kurzak, Johanni van Oostrum - Agata', 'Hanna Sosnowska-Bill, Sonia Wawrzyńska-Dettlaff - Anusia', 'Alec Carlson, Rafał Bartmiński - Maks', 'Dariusz Machej, Jakub Michalski - Kasper', 'Jacek Jaskuła, Mario Klein - Kuno', 'Łukasz Rosiak, Sergii Borzov - Kilian', 'Szymon Mechliński, Hubert Zapiór - Otokar', 'Remigiusz Łukomski, Arkadiusz Jakus - Samiel/Pustelnik', 'Maciej Tomaszewski, Edward Kulczyk - Narrator', 'Urszula Czupryńska, Liliana Jędrzejczak', 'Aleksandra Malisz, Canan Kalkir', 'Zoia Rolińska, Maria Kulisiewicz, Katarzyna Toboła, Aleksandra Malisz - Druhny', 'Michał Czok, Jacek Lech - Myśliwi'],
        orchestra: 'Soloists, Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Cezary Tomaszewski',
        conductor: 'Łukasz Borowicz',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    // {
        // id: '43',
    //     date: '01.10.2021',
    //     place: 'Wrocław Opera',
    //     title: 'Don Giovanni',
    //     subtitle: 'Wolfgang Amadeus Mozart',
    //     cast: 'Hubert Zapiór, Jakub Michalski, Gabriela Gołaszewska, Aleksandra Opała, Jędrzej Tomczyk, Hanna Sosnowska-Bill, Sergii Borzov, Jerzy Butryn',
    //     orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'André Heller-Lopes',
    //     conductor: 'Paweł Przytocki',
    //     conductorAssistant: 'Rafał Karczmarczyk',
    //     assistant: 'yes'
    // },
    {
        id: '42',
        date: '27.08.2021',
        place: 'Wrocław Opera',
        title: 'Tosca',
        subtitle: 'Giacomo Puccini',
        cast: ['Lianna Haroutounian, Ewa Vesin - Floria Tosca', 'Giorgi Sturua, Tadeusz Szlenkier - Mario Cavaradossi', 'Ambrogio Maestri, Mikołaj Zalasiński - Baron Scarpia', 'Jakub Michalski - Cesare Angelotti', 'Jacek Jaskuła - Zakrystian', 'Aleksander Zuchowicz - Spoletta', 'Andrzej Zborowski - Sciarrone', 'Marek Klimczak, Jerzy Szlachcic - Straznik', 'Helena Czech, Nina Hansen - Pastuszek'],
        orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Michael Gieleta',
        conductor: 'Bassem Akiki',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    {
        id: '41',
        date: '04.06.2021',
        place: 'Wrocław Opera',
        title: 'Cosi fan tutte',
        subtitle: 'Wolfgang Amadeus Mozart',
        cast: ['Sonia Wawrzyńska-Dettlaff, Aleksandra Łaska - Fiordiligi', 'Elwira Janasik, Zuzanna Nalewajek - Dorabella', 'Maria Rozynek-Banaszak, Hanna Okońska - Despina', 'Adrian Domarecki, Ted Black - Ferrando', 'Paweł Trojak, Paweł Horodyski - Guglielmo', 'Tomasz Rudnicki, Dariusz Machej - Don Alfonso'],
        orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'André Heller-Lopes',
        conductor: 'Adam Banaszak',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    // {
        // id: '40',
    //     date: '12.03.2021',
    //     place: 'Wrocław Opera',
    //     title: 'La Cenerentola',
    //     subtitle: 'Gioacchino Rossini',
    //     cast: '',
    //     orchestra: 'Soloists, Choir, Ballet and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'Irina Brook',
    //     conductor: 'Adam Banaszak',
    //     conductorAssistant: 'Rafał Karczmarczyk',
    //     assistant: 'yes'
    // },
    // {
    //      id: '39',
    //     date: '26.02.2021',
    //     place: 'Wrocław Opera',
    //     title: 'Les Pêcheurs de perles',
    //     subtitle: 'Georges Bizet',
    //     cast: 'Ewa Majcherczyk, Szymon Komasa, Jarosław Kwaśniewski, Jakub Michalski',
    //     orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'Waldemar Zawodziński',
    //     conductor: 'Paweł Przytocki',
    //     conductorAssistant: 'Rafał Karczmarczyk',
    //     assistant: 'yes'
    // },
    // {
    //  id: '38',
    //     date: '6.02.2021',
    //     place: 'Wrocław Opera',
    //     title: 'Nabucco',
    //     subtitle: 'Giuseppe Verdi',
    //     cast: 'Mikołaj Zalasiński, Łukasz Załęski, Rafał Siwek, Karina Skrzeszewska',
    //     orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'Krystian Lada',
    //     conductor: 'Bassem Akiki',
    //     conductorAssistant: 'Rafał Karczmarczyk',
    //     assistant: 'yes'
    // },
    // {
    //      id: '37',
    //     date: '16.01.2021',
    //     place: 'Wrocław Opera',
    //     title: 'Les Contes d’Hoffmann',
    //     subtitle: 'Jacques Offenbach',
    //     cast: 'Charles Castronovo, Aleksandra Opała, Maria Rozynek-Banaszak, Eliza Kruszyńska, Ekaterina Siurina, Jerzy Butryn, Piotr Bunzler, Edward Kulczyk, Aleksander Zuchowicz, Hanna Sosnowska, Łukasz Rosiak, Jakub Michalski, Barbara Bagińska, Maciej Krzysztyniak, Jędrzej Tomczyk, Andrzej Zborowski',
    //     orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'Waldemar Zawodziński',
    //     conductor: 'Bassem Akiki',
    //     conductorAssistant: 'Rafał Karczmarczyk',
    //     assistant: 'yes'
    // },
    // {
    //  id: '36',
    //     date: '20.11.2020',
    //     place: 'Wrocław Opera',
    //     title: 'La Bohème',
    //     subtitle: 'Giacomo Puccini',
    //     cast: 'Adriana Ferfecka, Piotr Buszewski, Maria Rozynek-Banaszak, Adam Zaremba, Tomasz Rudnicki, Jerzy Butryn, Maciej Krzysztyniak, Marek Klimczak, Piotr Bunzler, Jerzy Szlachcic, Aliaksandr Bardasau',
    //     orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'Waldemar Zawodziński',
    //     conductor: 'Bassem Akiki',
    //     conductorAssistant: 'Rafał Karczmarczyk',
    //     assistant: 'yes'
    // },
    {
        id: '35',
        date: '16.08.2020',
        place: 'Wrocław Opera',
        title: 'Curlew River',
        subtitle: 'Benjamin Britten',
        cast: ['Aleksander Zuchowicz', 'Łukasz Rosiak', 'Marcin Bronikowski', 'Tomasz Rudnicki', 'Maria Rozynek-Banaszak'],
        orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Michał Znaniecki',
        conductor: 'Adam Banaszak',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    {
        id: '34',
        date: '01.08.2020',
        place: 'Wrocław Opera',
        title: 'The Burning Fiery Furnace',
        subtitle: 'Benjamin Britten',
        cast: ['Tomasz Rudnicki', 'Krystian Krzeszowiak', 'Marcin Bronikowski', 'Aleksander Zuchowicz', 'Jakub Michalski', 'Hanna Sosnowska', 'Łukasz Rosiak'],
        orchestra: 'Choir and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Michał Znaniecki',
        conductor: 'Adam Banaszak',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    // {
    //      id: '33',
    //     date: '11.03.2020',
    //     place: 'Wrocław Opera',
    //     title: 'Candide',
    //     subtitle: 'Leonard Bernstein',
    //     cast: '',
    //     orchestra: 'Soloists, Choir and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'Hanna Marasz',
    //     conductor: 'Adam Banaszak',
    //     conductorAssistant: 'Rafał Karczmarczyk',
    //     assistant: 'yes'
    // },
    {
        id: '32',
        date: '01.03.2020',
        place: 'Wrocław Opera',
        title: 'Yemaya',
        subtitle: 'Zygmunt Krauze',
        cast: '',
        orchestra: 'Soloists, Choir, Children’s Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Hanna Marasz',
        conductor: 'Rafał Karczmarczyk',
    },
    // {
    //      id: '31',
    //     date: '04.01.2020',
    //     place: 'Wrocław Opera',
    //     title: 'Orfeo ed Euridice',
    //     subtitle: 'Christoph Willibald Gluck',
    //     cast: 'Jacek Jaskuła, Bożena Bujnicka, Hanna Sosnowska',
    //     orchestra: 'Choir and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'Mariusz Treliński',
    //     conductor: 'Przemysław Fiugajski',
    //     conductorAssistant: 'Rafał Karczmarczyk',
    //     assistant: 'yes'
    // },
    {
        id: '30',
        date: '31.12.2019',
        place: 'Rybnik Philharmonic',
        title: 'New Year’s Eve gala',
        subtitle: 'Benjamin Britten',
        cast: ['Grażyna Brodzińska', 'Marcin Jajkiewicz'],
        orchestra: 'Orchestra of Rybnik Philharmonic',
        choreography: '',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '29',
        date: '28.12.2019 | 29.12.2019',
        place: 'Wrocław Opera',
        title: 'The Nutcracker',
        subtitle: 'Pyotr Tchaikovsky',
        cast: '',
        orchestra: 'Ballet, Children’s Choir and Orchestra of Wrocław Opera',
        choreography: 'Youri Vámos',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    // {
    //      id: '28',
    //     date: '14.12.2019',
    //     place: 'Wrocław Opera',
    //     title: 'Madame Butterfly',
    //     subtitle: 'Giacomo Puccini',
    //     cast: 'Anna Lichorowicz, David Baños, Barbara Bagińska, Jacek Jaskuła, Edward Kulczyk, Łukasz Rosiak, Tomasz Rudnicki, Dorota Dutkowska, Sergii Borzov, Marek Klimczak, Andrzej Zborowski, Dorota Dutkowska, Katarzyna Słowińska, Ewa Wadyńska-Wąsikiewicz',
    //     orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'Giancarlo del Monaco',
    //     conductor: 'Tomasz Tokarczyk',
    //     conductorAssistant: 'Rafał Karczmarczyk',
    //     assistant: 'yes'
    // },
    {
        id: '27',
        date: '06.12.2019',
        place: 'Wrocław Opera',
        title: 'The Nutcracker',
        subtitle: 'Pyotr Tchaikovsky',
        cast: ['Filip Veverka - Scrooge', 'Natsuki Katayama - Klara', 'Łukasz Ożga - Książę/Dziadek do orzechów', 'Stefano Pietragalla - Bob Crachit', 'Hannah Cho - Pani Crachit/Królowa Śnieżynek', 'Won June Choi, Michela Villa, Andrea Aguado Camp,o Anna Mendakiewicz, María Isabel Trabalón Sanjuán - Taniec hiszpański', 'Stefano Pietragalla, Jaume Deulofeu Segui - Taniec rosyjski', 'Magdalena Kurilec-Malinowska - Taniec chiński', 'Sherly Belliard, Ludovico Tambara, Sergi Martinez Castello, Gabriele Lucci, Pablo Martinez Mendez - Arlekiny i Jackie', 'Andrea Aguado Campo, Daniel Agudo Gallardo - Taniec orientalny', 'Magdalena Kurilec-Malinowska, Olga Markari, Won June Choi, Andrzej Malinowski - Solo Walc kryształowy (kwiatów)'],
        orchestra: 'Ballet, Children’s Choir and Orchestra of Wrocław Opera',
        choreography: 'Youri Vámos',
        director: '',
        conductor: 'Adam Banaszak',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    {
        id: '26',
        date: '01.12.2019',
        place: 'Wrocław Opera',
        title: 'The Cheating Flea',
        subtitle: 'Maciej Małecki',
        cast: '',
        orchestra: 'Soloists and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Anna Seniuk',
        conductor: 'Rafał Karczmarczyk'
    },
    {
        id: '25',
        date: '23.11.2019',
        place: 'Wrocław Opera',
        title: 'Giselle',
        subtitle: 'Adam Adolphe',
        cast: '',
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Jean Coralli, Jules Perrot',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '24',
        date: '17.11.2019',
        place: 'Wrocław Opera',
        title: 'Traviata',
        subtitle: 'Giuseppe Verdi',
        cast: ['Edyta Piasecka - Violetta', 'Jadwiga Postrożna - Flora', 'Dorota Dutkowska - Annina', 'Andrzej Lampert - Alfredo', 'Adam Kruszewski - Germont', 'Jędrzej Tomczyk - Gaston', 'Łukasz Rosiak - Barone', 'Jakub Michalski - Marchese', 'Tomasz Rudnicki - Dottore', 'Edward Kulczyk - Giuseppe', 'Paweł Walankiewicz - Słuzący Flory', 'Aleksander Bardasau - Posłaniec'],
        orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: 'Karol Urbański',
        director: 'Grażyna Szapołowska',
        conductor: 'Adam Banaszak',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    {
        id: '23',
        date: '08.11.2019 | 10.11.2019',
        place: 'Wrocław Opera',
        title: 'Halka',
        subtitle: 'Stanisław Moniuszko',
        cast: ['Anna Lichorowicz - Halka', 'Jury Horodecki - Jontek', 'Tomasz Rudnicki - Stolnik', 'Hanna Sosnowska - Zofia', 'Marcin Hutek - Janusz', 'Jakub Michalski - Dziemba', 'Andrzej Zborowski - Dudarz', 'Jędrzej Tomczyk - Góral', 'Paweł Walankiewicz - Gość Stolnika I', 'Jacek Lech - Gość Stolnika II', 'Aliaksandr Bardasau - Gość Stolnika III'],
        orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Grażyna Szapołowska',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '22',
        date: '03.11.2019',
        place: 'Wrocław Opera',
        title: 'Giselle',
        subtitle: 'Adam Adolphe',
        cast: '',
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Jean Coralli, Jules Perrot',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '21',
        date: '20.10.2019',
        place: 'Wrocław Opera',
        title: 'Le Carnaval des animaux',
        subtitle: 'Camille Saint-Saëns',
        cast: ['Magdalena Kurilec-Malinowska', 'Łukasz Ożga', 'Andrea Aguado Campo', 'Sherly Belliard', 'Paula Dzwonik', 'Lucie Le May', 'Maria Trabalon', 'Won June Choi', 'Andrzej Malinowski', 'Pablo Martinez Mendez', 'Sherly Belliard', 'Michela Villa', 'Anita Tashkinova, Ivan Zhukov - piano'],
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Anna Hop',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    // {
    //     id: '20',
    //     date: '17.10.2019',
    //     place: 'Wrocław Opera',
    //     title: 'La Bohème',
    //     subtitle: 'Giacomo Puccini',
    //     cast: 'Ilya Selivanov, Iwona Socha, Jacek Jaskuła, Ewa Majcherczyk, Jakub Michalski, Łukasz Rosiak, Marek Klimczak, Jacek Ozimkowski, Piotr Bunzler, Jerzy Szlachcic, Marek Klimczak',
    //     orchestra: 'Choir, Ballet and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'Waldemar Zawodziński',
    //     conductor: 'Tomasz Tokarczyk',
    //     conductorAssistant: 'Rafał Karczmarczyk',
    //     assistant: 'yes'
    // },
    {
        id: '19',
        date: '13.10.2019 | 15.10.2019',
        place: 'Wrocław Opera',
        title: 'The Cheating Flea',
        subtitle: 'Maciej Małecki',
        cast: '',
        orchestra: 'Soloists and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Anna Seniuk',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '18',
        date: '06.10.2019',
        place: 'Wrocław Opera',
        title: 'Yemaya',
        subtitle: 'Zygmunt Krauze',
        cast: ['Bożena Bójnicka', 'Tymoteusz Gaura', 'Jacek Jaskuła', 'Bartłomiej Kłos', 'Dorota Dutkowska', 'Aleksander Zuchowicz', 'Tomasz Rudnicki', 'Jędrzej Tomczyk', 'Bolesław Słowiński', 'Sergei Borzov', 'Piotr Bunzler', 'Paweł Walankiewicz', 'Sylwia Miazga'],
        orchestra: 'Choir, Children’s Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Hanna Marasz',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '17',
        date: '01.10.2019',
        place: 'Wrocław Opera',
        title: 'Yemaya',
        subtitle: 'Zygmunt Krauze',
        cast: '',
        orchestra: 'Soloist, Choir, Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Hanna Marasz',
        conductor: 'Adam Banaszak',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    {
        id: '16',
        date: '17.09.2019 | 18.09.2019',
        place: 'Wrocław Opera',
        title: 'The Cheating Flea',
        subtitle: 'Maciej Małecki',
        cast: '',
        orchestra: 'Soloists and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Anna Seniuk',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '15',
        date: '15.06.2019 | 16.06.2019',
        place: 'Wrocław Opera',
        title: 'Wieczór Polski',
        subtitle: '',
        piece1: 'Fryderyk Chopin - Piano Concerto No.2 in F minor',
        piece2: 'Wojciech Kilar - Exodus',
        cast: ['Julia Kociuban – piano (Chopin)', 'Anita Tashkinova, Ivan Zhukov – piano (Kilar)', 'Dajana Kłos', 'Łukasz Ożga', 'Robert Kędziński', 'Magdalena Kurilec-Malinowska', 'Andrzej Malinowski'],
        orchestra: 'Ballet, Choir and Orchestra of Wrocław Opera',
        choreography: 'Emil Wesołowski, Teresa Kujawa',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    // {
    //     id: '14',
    //     date: '01.03.2019',
    //     place: 'Wrocław Opera',
    //     title: 'Yemaya',
    //     subtitle: 'Zygmunt Krauze',
    //     cast: ['Maria Rozynek-Banaszak', 'Helena Czech', 'Tomasz Rudnicki', 'Jakub Michalski', 'Dorota Dutkowska', 'Hanna Sosnowska', 'Aleksander Zuchowicz', 'Jędrzej Tomczyk', 'Bolesław Słowiński', 'Sergei Borzov', 'Piotr Bunzler', 'Paweł Walankiewicz', 'Sylwia Miazga'],
    //     orchestra: 'Choir, Children’s Choir, Ballet and Orchestra of Wrocław Opera',
    //     choreography: '',
    //     director: 'Hanna Marasz',
    //     conductor: 'Rafał Karczmarczyk',
    // },
    {
        id: '13',
        date: '30.01.2019 | 01.02.2019',
        place: 'Wrocław Opera',
        title: 'Les Pêcheurs de perles',
        subtitle: 'Georges Bizet',
        cast: ['Hanna Sosnowska - Leila', 'Jarosław Kwaśniewski - Nadir', 'Tomasz Rudnicki - Zurga', 'Jakub Michalski - Nurabad'],
        orchestra: 'Choir. Ballet and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Waldemar Zawodziński',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '12',
        date: '02.12.2018 | 30.11.2018',
        place: 'Wrocław Opera',
        title: 'Giselle',
        subtitle: 'Adam Adolphe',
        cast: ['Remy Lamping', 'Robert Kędziński', 'Anna Szopa-Kimso', 'Won June Choi', 'Daniel Agudo Gallardo', 'Martyna Dobosz', 'Andrzej Malinowski', 'Sergii Oberemok', 'Ines Furuhashi-Huber', 'Hannah Cho', 'Łukasz Ożga', 'Natsuki Katayama', 'Sherly Belliard', 'Marina Carrasco', 'Paula Dzwonik', 'Dajana Kłos'],
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Jean Coralli, Jules Perrot',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '11',
        date: '27.10.2018',
        place: 'Wrocław Opera',
        title: 'Giselle',
        subtitle: 'Adam Adolphe',
        cast: '',
        orchestra: 'Ballet and Orchestra of Wrocław Opera',
        choreography: 'Jean Coralli, Jules Perrot',
        conductor: 'Ayyub Guliev',
        conductorAssistant: 'Rafał Karczmarczyk',
        premiere: 'PREMIERE',
        assistant: 'yes'
    },
    {
        id: '10',
        date: '21.10.2018',
        place: 'Wrocław Opera',
        title: 'The Cheating Flea',
        subtitle: 'Maciej Małecki',
        cast: '',
        orchestra: 'Soloists and Orchestra of Wrocław Opera',
        choreography: '',
        director: 'Anna Seniuk',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '9',
        date: '02.09.2018',
        place: 'Cracow Philharmony',
        title: 'Jeszcze Polska Muzyka',
        subtitle: '',
        piece1: 'Juliusz Zarębski - Polonez triumfalny A-dur op.11',
        piece2: 'Franciszek Lessel - Koncert fortepianowy C-dur',
        piece3: 'Antonín Dvořák - VIII Symfonia G-dur op.88',
        cast: ['Beata Bilińska - piano', 'Dariusz Stańczuk - słowo'],
        orchestra: 'Beethoven Academy Orchestra',
        choreography: '',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '8',
        date: '03.11.2018',
        place: 'ICE Cracow',
        title: '100 lat Niepodległej',
        subtitle: '',
        cast: ['Katarzyna Oleś-Blacha', 'Bogusław Morka', 'Rafał Pawnuk'],
        orchestra: 'Mariański Choir, Young Cracow Philharmony',
        choreography: '',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '7',
        date: '22.01.2018',
        place: 'Academy of Music in Cracow',
        title: 'Mozart’s Opera Gala',
        subtitle: '',
        cast: ['Students Academy of Music in Cracow'],
        orchestra: 'Orchestra of Cracow Opera',
        choreography: '',
        director: '',
        conductor: 'Tomasz Tokarczyk',
        conductorAssistant: 'Rafał Karczmarczyk',
        assistant: 'yes'
    },
    {
        id: '6',
        date: '31.12.2017',
        place: '',
        title: 'New Year’s Eve gala',
        subtitle: '',
        cast: ['Grażyna Brodzińska', 'Marcin Jajkiewicz', 'Dariusz Stańczuk'],
        orchestra: 'Orkiestra CREO',
        choreography: '',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '5',
        date: '22.10.2017',
        place: 'ICE Cracow',
        title: 'Beatles Symphonics',
        subtitle: '',
        cast: '',
        orchestra: 'Beethoven Academy Orchestra',
        choreography: '',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '4',
        date: '02.10.2017',
        place: 'Poznań Opera House',
        title: 'Poznań Operetta Gala',
        subtitle: '',
        cast: ['Grażyna Brodzińska', 'Adam Zdunikowski', 'Adam Szerszeń'],
        orchestra: 'Young Cracow Philharmony',
        choreography: '',
        director: '',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '3',
        date: '11.04.2017',
        place: 'Cracow Opera',
        title: 'The Marriage of Figaro',
        subtitle: 'Wolfgang Amadeusz Mozart',
        cast: ['Students Academy of Music in Cracow'],
        orchestra: 'Orchestra of Cracow Opera',
        choreography: '',
        director: '',
        conductor: 'Tomasz Tokarczyk',
        conductorAssistant: 'Rafał Karczmarczyk',
        assistant: 'yes'
    },
    {
        id: '2',
        date: '24.07.2016',
        place: 'Czorsztyn',
        title: 'Zamek na Czorsztynie',
        subtitle: 'Karol Kurpiński',
        premiere: 'PREMIERE',
        cast: ['Students Academy of Music in Cracow'],
        orchestra: '',
        choreography: '',
        director: 'Monika Rasiewicz',
        conductor: 'Rafał Karczmarczyk',
    },
    {
        id: '1',
        date: '13.05.2016',
        place: 'Cracow Opera',
        title: 'The Haunted Manor',
        subtitle: 'Stanisław Moniuszko',
        cast: ['Students Academy of Music in Cracow'],
        orchestra: 'Orchestra of Cracow Opera',
        choreography: '',
        director: '',
        conductor: 'Rafał Jacek Delekta',
        conductorAssistant: 'Rafał Karczmarczyk',
        assistant: 'yes'
    },
];