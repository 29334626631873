import React from "react";
import styles from "../Curriculum/Curriculum.module.css";

const Experience = ({ title }) => {

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{title}</h2>
      
      <div id='slider' className={styles.row_posters}>
        <div className={styles.frame}>
          <div className={styles.content}>
            <div className={styles.date}>2018 - current</div>
            <div className={styles.institution}>Wrocław Opera House</div>
            
            <div className={styles.role}>Conductor • Interim Music Director</div>
            <div className={styles.date1}>2024 - current</div>

            <div className={styles.role}>Artistic Assistant • Conductor</div>
            {/* <div className={styles.date1}>2019 - 2024</div> */}
            <div className={styles.date1}>2023 - 2024</div>
            <div className={styles.date1}>2019 - 2022</div>
            
            <div className={styles.role}>Conductor in Residence</div>
            <div className={styles.date1}>2018 - 2019</div>
          </div>
        </div>
        <div className={styles.frame}>
          <div className={styles.content}>
            <div className={styles.date}>2015 - 2018</div>
            <div className={styles.institution}>Youth Cracow Philharmonic</div>
            <div className={styles.role}>Conductor • Assistant</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience